import { Component, EventEmitter, Input, Output, computed, input } from '@angular/core'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'

import { ConnectableChannel } from '~features/integrations/models/connectable-channel.model'
import { Channel } from '~features/projects/models/channel.model'

import { AlertComponent } from '../../../ui/alert/alert.component'
import { ChannelCardComponent } from '../channel-card/channel-card.component'

const sortChannels = <T extends Channel | ConnectableChannel>(channels: T[]): T[] =>
  [...channels].sort((a, b) => {
    if (a.connected && !b.connected) {
      return 1
    }
    if (!a.connected && b.connected) {
      return -1
    }
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

@Component({
  selector: 'sb-channel-list',
  styleUrls: ['./channel-list.component.scss'],
  standalone: true,
  imports: [MatListModule, ChannelCardComponent, AlertComponent, TranslocoModule],
  template: `
    <div class="channels-list">
      <mat-selection-list>
        @for (channel of channels(); track channel) {
          <mat-list-item (click)="onSelectChannel(channel)" [disabled]="channel.connected || !available">
            <sb-channel-card
              [available]="!channel.connected && available"
              [channel]="channel"
              [clickable]="clickable"
              [removable]="false"
              transparent
            />
          </mat-list-item>
        }
        @if (!hasChannels) {
          <mat-list-item disabled>
            <sb-alert variant="neutral">{{ 'words.noResult' | transloco }}</sb-alert>
          </mat-list-item>
        }
      </mat-selection-list>
    </div>
  `,
})
export class ChannelListComponent<T extends Channel | ConnectableChannel> {
  @Input() available = true
  @Input() clickable = true
  @Output() selectChannel = new EventEmitter<T>()
  channels = input<T[]>()

  sortedChannels = computed(() => sortChannels(this.channels()))

  get hasChannels(): boolean {
    return this.channels().length > 0
  }

  onSelectChannel(channel: T): void {
    if (!this.clickable || !this.available || channel.connected) {
      return
    }
    this.selectChannel.emit(channel)
  }
}
